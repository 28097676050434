<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread breadcrumb-1400" :list="list"></Breadcrumb>
    </div>
    <div class="foun-box">
      <div class="foun-title">
        <div class="title-left">
          <img :onerror="defaultImg" :src="detailList.image" />
        </div>
        <div class="title-right">
          <div class="name">{{ detailList.name }}</div>
          <div class="detailed">
            <div class="detailed-left">职业</div>
            <div class="detailed-right">{{ detailList.typeName }}</div>
            <div class="detailed-left">民族</div>
            <div class="detailed-right">{{ detailList.nation }}</div>
            <div class="detailed-left">籍贯</div>
            <div class="detailed-right">{{ detailList.job }}</div>
            <div class="detailed-left">毕业学校</div>
            <div class="detailed-right">{{ detailList.school }}</div>
          </div>
          <div class="content">{{ detailList.descript }}</div>
        </div>
      </div>
      <div class="foun-filt">
        <div class="filt-left">
          <div
            v-for="item in filtList"
            @click="filtClick(item)"
            :key="item.id"
            :class="['filt', { highlight: item.id === selectedItemId }]"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="detailList.pictures" class="filt-right">剧目相册</div>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div ref="topic" class="topic">
            <div class="topic-title">
              <i class="icon"></i>
              <div class="title">话剧作品</div>
            </div>
            <div class="topic-content">
              <div class="left">
                <div
                  v-for="(item, index) in detailList.repertoireList"
                  :key="item.id"
                  class="detail"
                  v-if="index % 2 === 0"
                >
                  <div
                    @click="toOpus(item)"
                    :title="item.repertoireName"
                    class="detail-left"
                  >
                    {{ item.repertoireName }}
                  </div>
                  <!-- <div v-if="item.role && page == 4" class="detail-right">
                    <div style="color: #909399">{{ "饰：" }}</div>
                    {{ item.role }}
                  </div>
                  <div v-if="item.role && page != 4" class="detail-right">
                    {{ item.role }}
                  </div> -->
                  <div v-if="item.role" class="detail-right">
                    <div
                      v-if="!judgeList.includes(item.role)"
                      style="color: #909399"
                    >
                      {{ "饰：" }}
                    </div>
                    {{ item.role }}
                  </div>
                </div>
              </div>
              <div class="right">
                <div
                  v-for="(item, index) in detailList.repertoireList"
                  :key="item.id"
                  class="detail"
                  v-if="index % 2 !== 0"
                >
                  <div
                    @click="toOpus(item)"
                    :title="item.repertoireName"
                    class="detail-left"
                  >
                    {{ item.repertoireName }}
                  </div>
                  <!-- <div v-if="item.role && page == 4" class="detail-right">
                    <div style="color: #909399">{{ "饰：" }}</div>
                    {{ item.role }}
                  </div> -->
                  <div v-if="item.role" class="detail-right">
                    <div
                      v-if="!judgeList.includes(item.role)"
                      style="color: #909399"
                    >
                      {{ "饰：" }}
                    </div>
                    {{ item.role }}
                  </div>
                  <!-- <div v-if="item.role && page != 4" class="detail-right">
                    {{ item.role }}
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div ref="dynamic" class="dynamic">
            <div class="dynamic-title">
              <i class="icon"></i>
              <div class="title">人物动态</div>
            </div>
            <div class="dynamic-content">
              <!-- <div class="content-left">
                                <img :src="item.image">
                            </div>
                            <div class="content-right">
                                <div class="top">{{ item.title }}</div>
                                <div class="mid">
                                    <div class="mid-left">来源：{{ item.source }}</div>
                                    <div class="mid-right">{{ item.publishDate }}</div>
                                </div>
                                <div class="detail">
                                    {{ convertToPlainText(item.body) }}
                                </div>
                                <div class="bottom">
                                    <img src="../../../assets/imgs/bottom-bg.png">
                                </div>
                            </div> -->
              <div v-html="detailList.artwork"></div>
            </div>
          </div>
          <div ref="other" class="other">
            <div class="topic-title">
              <i class="icon"></i>
              <div class="title">其他作品</div>
            </div>
            <div class="topic-content">
              <!-- <div class="left">
                                <div v-for="(item,index) in detailList.artworkOther " :key="item.id" v-if="index %2 === 0" class="detail">
                                    <div class="detail-left">{{ item.title }}</div>
                                    <div class="detail-left">{{ item.date }}</div>
                                    <div class="detail-right">{{ '饰演角色：' + item.role }}</div>
                                </div>
                            </div>
                            <div class="right">
                                <div v-for="(item,index) in detailList.artworkOther " :key="item.id" v-if="index %2 !==0" class="detail">
                                    <div class="detail-left">{{ item.title }}</div>
                                    <div class="detail-left">{{ item.date }}</div>
                                    <div class="detail-right">{{ '饰演角色：' + item.role }}</div>
                                </div>
                            </div> -->
              <div v-html="detailList.artworkOther"></div>
            </div>
          </div>
          <div v-if="honorList.length !== 0" ref="honor" class="honor-box">
            <div class="honor-title">
              <i class="icon"></i>
              <div class="title">荣誉记录</div>
            </div>
            <div v-for="item in honorList" :key="item" class="honor">
              {{ item }}
            </div>
          </div>
        </div>
        <div v-if="detailList.pictures" class="bottom-right">
          <div class="img1">
            <el-image
              :src="
                detailList.pictures && detailList.pictures[0]
                  ? detailList.pictures[0]
                  : ''
              "
              :preview-src-list="[
                detailList.pictures && detailList.pictures[0]
                  ? detailList.pictures[0]
                  : '',
              ]"
            ></el-image>
          </div>
          <div class="img2">
            <el-image
              :src="
                detailList.pictures && detailList.pictures[1]
                  ? detailList.pictures[1]
                  : ''
              "
              :preview-src-list="[
                detailList.pictures && detailList.pictures[1]
                  ? detailList.pictures[1]
                  : '',
              ]"
            ></el-image>
          </div>
          <div class="img3">
            <el-image
              :src="
                detailList.pictures && detailList.pictures[2]
                  ? detailList.pictures[2]
                  : ''
              "
              :preview-src-list="[
                detailList.pictures && detailList.pictures[2]
                  ? detailList.pictures[2]
                  : '',
              ]"
            ></el-image>
          </div>
          <div class="img4">
            <el-image
              :src="
                detailList.pictures && detailList.pictures[3]
                  ? detailList.pictures[3]
                  : ''
              "
              :preview-src-list="[
                detailList.pictures && detailList.pictures[3]
                  ? detailList.pictures[3]
                  : '',
              ]"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import { getDetail } from "@/service/PeopleEntertainers";
import { getSystemDict } from "@/service/system";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/defaultDiagram.jpg") + '"',
      list: [],
      honorList: [],
      filtList: [
        {
          id: 1,
          name: "话剧作品",
          refName: "topic",
        },
        {
          id: 2,
          name: "人物动态",
          refName: "dynamic",
        },
        {
          id: 3,
          name: "其他作品",
          refName: "other",
        },
        {
          id: 4,
          name: "荣誉记录",
          refName: "honor",
        },
      ],
      dynamicList: [],
      detailList: {},
      selectedItemId: 1,
      page: "",
      judgeList: [],
    };
  },
  created() {
    this.getDetail(this.$route.query.id);
    this.pageFilter(this.$route.query.page);
    this.page = this.$route.query.page;
    this.$nextTick(() => {
      this.refList = this.$refs;
    });
    getSystemDict({ type: "check-role" }).then((res) => {
      this.judgeList = res.data.map((item) => item.value);
    });
  },
  mounted() {
    const isFirstVisit = sessionStorage.getItem("isFirstVisit_B") === null;

    if (isFirstVisit) {
      // 设置标记，表示B页面已经访问过一次
      sessionStorage.setItem("isFirstVisit_B", "false");

      // 执行刷新操作
      window.location.reload();
    }
  },
  methods: {
    toOpus(item) {
      window.open(`#/culture/collection/detail/${item.repertioireId}?from=dc`);
    },
    convertToPlainText(html) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
    },
    addFontSizeToUlTags(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("span");

      ulTags.forEach(function (span) {
        span.style.fontSize = "0.7292vw";
      });

      return doc.documentElement.innerHTML;
    },
    async getDetail(id) {
      const { data } = await getDetail(id);
      this.detailList = data;
      this.detailList.artworkOther = this.detailList.artworkOther ? this.addFontSizeToUlTags(this.detailList.artworkOther) : '';
      // if (typeof data.artwork === 'string' && data.artwork !== '') {
      //     this.detailList.artwork = JSON.parse(data.artwork);
      // }
      if (data.pictures) {
        this.detailList.pictures = data.pictures.split(",");
      }
      if (data.honor !== "") {
        this.honorList = data.honor.split("\n");
        console.log(this.honorList);
      }
    },
    filtClick({ id, refName }) {
      this.selectedItemId = id;
      this.refList[refName].scrollIntoView({ behavior: "smooth" });
    },
    pageFilter(page) {
      if (page == 1) {
        this.list = ["人艺人", "创始人", "创始人资料"];
        return;
      } else if (page == 2) {
        this.list = ["人艺人", "编剧", "编剧资料"];
        return;
      } else if (page == 3) {
        this.list = ["人艺人", "导演", "导演资料"];
        return;
      } else if (page == 4) {
        this.list = ["人艺人", "演员", "演员资料"];
        return;
      } else if (page == 5) {
        this.list = ["人艺人", "设计", "设计资料"];
        return;
      } else if (page == 6) {
        this.list = ["人艺人", "舞美", "舞美资料"];
        return;
      } else {
        this.list = ["人艺人", "详情"];
        return;
      }
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;
  font-family: Arial, Helvetica, sans-serif;

  .bg-top {
    position: absolute;
    width: 58.6979vw;
    height: 23.4375vw;
    z-index: -1;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    width: 11.1458vw;
    height: 55.2083vw;
    z-index: -1;
  }

  .foun-top {
    width: 41.6667vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.5417vw;
    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    width: 70.3125vw;
    margin: 1.0417vw 13.5417vw 2.6042vw 13.5417vw;
    height: auto;
    min-height: 52.0833vw;
    position: relative;
    /* 添加定位属性 */
    z-index: 5;
    background-color: #ffffff;

    .foun-title {
      width: 72.9167vw;
      height: 18.75vw;
      margin-top: 2.6042vw;
      display: flex;

      .title-left {
        width: 11.6667vw;
        height: 17.1875vw;
        margin-left: 2.6042vw;
        padding-top: 2.6042vw;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title-right {
        width: 54.4792vw;
        height: auto;
        margin-left: 1.5625vw;

        .name {
          font-size: 1.0417vw;
          margin-top: 3.125vw;
          height: 1.0417vw;
          line-height: 1.0417vw;
          font-weight: 800;
          text-align: left;
        }

        .detailed {
          margin-top: 1.0417vw;
          height: 1.5625vw;
          width: auto;
          display: flex;

          .detailed-left:first-of-type {
            margin-left: 0;
          }

          .detailed-left {
            width: 4.375vw;
            height: 1.5625vw;
            background: #f8f8fa;
            border-radius: 0.1042vw;
            line-height: 1.5625vw;
            margin-left: 5.2083vw;
            text-align: center;
            font-size: 0.8333vw;
          }

          .detailed-right {
            height: 1.5625vw;
            margin-left: 1.0417vw;
            line-height: 1.5625vw;
            font-size: 0.8333vw;
          }
        }

        .content {
          height: 9.375vw;
          margin-top: 3.6458vw;
          width: 49.4792vw;
          font-size: 0.8333vw;
          font-weight: 400;
          color: #212122;
          line-height: 1.5625vw;
          text-align: left;
          // display: -webkit-box;
          // -webkit-line-clamp: 5;
          // /* 显示的行数 */
          // -webkit-box-orient: vertical;
          // overflow: hidden;
          // text-overflow: ellipsis;
          overflow-y: auto;
        }
      }
    }

    .foun-filt {
      margin-top: 3.125vw;
      display: flex;
      width: auto;
      height: 1.5625vw;
      margin-left: 2.6042vw;

      .filt-left {
        height: 1.0417vw;
        width: auto;
        line-height: 1.0417vw;
        display: flex;

        .filt:first-of-type {
          margin: 0;
        }

        .filt {
          color: #212121;
          font-size: 1.0417vw;
          margin-left: 1.5625vw;
          min-width: 4.1667vw;
          cursor: pointer;
        }

        .highlight {
          color: #f9680d;
          font-size: 1.0417vw;
          margin-left: 1.5625vw;
          min-width: 4.1667vw;
          cursor: pointer;
        }
      }

      .filt-right {
        margin-left: 28.9063vw;
        color: #212121;
        font-size: 1.0417vw;
        width: 4.1667vw;
      }
    }

    .bottom {
      width: 100%;
      // height: 1394px;
      display: flex;

      .bottom-left {
        width: 50vw;
        margin-top: 1.5625vw;
        height: 100%;

        .topic {
          width: 38.8021vw;
          // height: 316px;
          margin-left: 2.6042vw;

          .topic-title {
            height: 0.9375vw;
            // width: 85px;
            min-width: 4.4271vw;
            display: flex;

            .icon {
              width: 0.1563vw;
              height: 0.8333vw;
              background: #21424b;
            }

            .title {
              // width: 72px;
              min-width: 3.75vw;
              height: 0.9375vw;
              font-size: 0.9375vw;
              font-weight: 800;
              color: #212122;
              line-height: 0.9375vw;
              margin-left: 0.5208vw;
            }
          }

          .topic-content {
            margin-top: 1.3021vw;
            width: 100%;
            // height: 278px;
            height: auto;
            display: flex;
            justify-content: space-between;

            .left {
              height: 100%;
              width: 18.2292vw;

              .detail:first-of-type {
                margin-top: 0;
              }

              .detail {
                height: 1.0938vw;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 1.5625vw;

                .detail-left:first-of-type {
                  margin-left: 0;
                }

                .detail-left {
                  height: 1.0938vw;
                  line-height: 1.0938vw;
                  width: 13.0208vw;
                  color: #f9680d;
                  font-size: 0.7292vw;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  cursor: pointer;
                }

                .detail-right {
                  display: flex;
                  height: 100%;
                  width: 10.4167vw;
                  color: #333;
                  font-size: 0.7292vw;
                }
              }
            }

            .right {
              height: 100%;
              width: 18.2292vw;

              .detail:first-of-type {
                margin-top: 0;
              }

              .detail {
                height: 1.0938vw;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 1.5625vw;

                .detail-left {
                  height: 1.0938vw;
                  width: 13.0208vw;
                  line-height: 1.0938vw;
                  color: #f9680d;
                  font-size: 0.7292vw;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  cursor: pointer;
                }

                .detail-right {
                  height: 100%;
                  width: 10.4167vw;
                  color: #333;
                  font-size: 0.7292vw;
                  display: flex;
                }
              }
            }
          }
        }

        .dynamic {
          width: 38.8021vw;
          // height: 430px;
          height: auto;
          margin-top: 2.0833vw;
          margin-left: 2.6042vw;

          .dynamic-title {
            height: 0.9375vw;
            // width: 85px;
            min-width: 4.4271vw;
            display: flex;

            .icon {
              width: 0.1563vw;
              height: 0.8333vw;
              background: #21424b;
            }

            .title {
              // width: 72px;
              min-width: 3.75vw;
              height: 0.9375vw;
              font-size: 0.9375vw;
              font-weight: 800;
              color: #212122;
              line-height: 0.9375vw;
              margin-left: 0.5208vw;
            }
          }

          .dynamic-content {
            margin-top: 1.0417vw;
            width: 44.7917vw;
            display: flex;
            justify-content: space-between;

            .content-left {
              width: 15vw;
              height: 9.4792vw;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }

            .content-right {
              height: 9.4792vw;
              width: 28.2292vw;

              .top {
                height: 1.0417vw;
                font-size: 1.0417vw;
                line-height: 1.0417vw;
                margin-top: 0.5208vw;
                font-weight: 500;
                color: #212122;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .mid {
                margin-top: 0.8333vw;
                color: #909399;
                font-size: 0.625vw;
                display: flex;
                height: 0.625vw;
                line-height: 0.625vw;

                .mid-right {
                  margin-left: 0.5208vw;
                }
              }

              .detail {
                margin-top: 1.0417vw;
                color: #606266;
                font-size: 0.6771vw;
                font-weight: 400;
                height: 2.0833vw;
                line-height: 1.0417vw;
                text-align: left;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                /* 显示的行数 */
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .bottom {
                margin-top: 2.0833vw;
                width: 3.0208vw;
                height: 0.7813vw;
              }
            }
          }
        }

        .other {
          width: 38.8021vw;
          // height: 316px;
          margin-top: 2.0833vw;
          margin-left: 2.6042vw;

          .topic-title {
            height: 0.9375vw;
            // width: 85px;
            min-width: 4.4271vw;
            display: flex;

            .icon {
              width: 0.1563vw;
              height: 0.8333vw;
              background: #21424b;
            }

            .title {
              // width: 72px;
              min-width: 3.75vw;
              height: 0.9375vw;
              font-size: 0.9375vw;
              font-weight: 800;
              color: #212122;
              line-height: 0.9375vw;
              margin-left: 0.5208vw;
            }
          }

          .topic-content {
            margin-top: 1.3021vw;
            width: 100%;
            // height: 278px;
            height: auto;
            display: flex;
            justify-content: space-between;

            .left {
              height: 100%;
              width: 15.625vw;

              .detail:first-of-type {
                margin-top: 0;
              }

              .detail {
                height: 1.0938vw;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 1.5625vw;
                line-height: 0.8333vw;

                .detail-left {
                  height: 1.0938vw;
                  width: 5.7292vw;
                  line-height: 1.0938vw;
                  color: #212122;
                  font-size: 0.7292vw;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                .detail-right {
                  height: 100%;
                  width: 8.3333vw;
                  color: #909399;
                  font-size: 0.7292vw;
                }
              }
            }

            .right {
              height: 100%;
              width: 15.625vw;

              .detail:first-of-type {
                margin-top: 0;
              }

              .detail {
                height: 1.0938vw;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 1.5625vw;
                line-height: 0.9375vw;

                .detail-left {
                  height: 1.0938vw;
                  line-height: 0.9375vw;
                  width: 5.7292vw;
                  color: #212122;
                  font-size: 1.0938vw;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                .detail-right {
                  height: 100%;
                  width: 7.8125vw;
                  color: #909399;
                  font-size: 0.7292vw;
                }
              }
            }
          }
        }

        .honor-box {
          width: 100%;
          // height: 300px;
          margin-top: 2.0833vw;
          margin-left: 2.6042vw;
          margin-bottom: 2.0833vw;
          overflow: hidden;

          .honor-title {
            height: 0.9375vw;
            width: 4.4271vw;
            display: flex;

            .icon {
              width: 0.1563vw;
              height: 0.8333vw;
              background: #21424b;
            }

            .title {
              // width: 72px;
              min-width: 3.75vw;
              height: 0.9375vw;
              font-size: 0.9375vw;
              font-weight: 800;
              color: #212122;
              line-height: 0.9375vw;
              margin-left: 0.5208vw;
            }
          }

          .honor {
            width: 100%;
            // height: 14px;
            margin-top: 1.0417vw;
            font-size: 0.8333vw;
            // font-weight: 800;
            line-height: 1.4063vw;
            text-align: left;
          }
        }
      }

      .bottom-right {
        width: 20.8333vw;
        height: 29.0625vw;
        margin-left: 2.0833vw;
        margin-top: 1.0417vw;
        position: relative;

        .img1 {
          position: absolute;
          left: 1.5625vw;
          top: 1.5625vw;
          width: 8.3333vw;
          height: 6.25vw;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        .img2 {
          position: absolute;
          left: 10.9375vw;
          top: 1.5625vw;
          width: 8.3333vw;
          height: 12.4479vw;

          img {
            width: 8.3333vw;
            object-fit: contain;
          }
        }

        .img3 {
          position: absolute;
          left: 1.5625vw;
          top: 13.5417vw;
          width: 8.3333vw;
          height: 12.4479vw;

          img {
            width: 100%;
            object-fit: contain;
          }
        }

        .img4 {
          position: absolute;
          left: 10.9375vw;
          top: 15.0521vw;
          width: 8.3333vw;
          height: 12.4479vw;

          img {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
/* 修改滚动条的高度和宽度 */
::-webkit-scrollbar {
  width: 0.5208vw; /* 滚动条宽度 */
  height: 0.5208vw; /* 滚动条高度 */
}

/* 修改滚动条的滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #21424b; /* 滑块颜色 */
  border-radius: 0.2604vw; /* 滑块圆角 */
}

/* 修改滚动条的轨道样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 轨道颜色 */
  border-radius: 0.2604vw; /* 轨道圆角 */
}
</style>
